import { toast, Bounce } from "react-toastify";

export const roles = [
  { value: "admin", label: "Admin" },
  { value: "user", label: "User" },
];
export const DEFAULT_ERROR_NOTIFICATION = "Something went wrong!";

export const TOASTR_OPTIONS = {
  // CUSTUM TOSTIFY
  position: toast.POSITION.TOP_RIGHT,
  transition: Bounce,
  // theme: 'colored',
};

//TODO make better implementation? maybe use connectionTypeId to identify
export const ignoreConnectionWarningForTaskTypes = [
  "14",
  "15",
  "16",
  "17",
  "21",
  "26",
  "31",
  "32",
  "33",
];

export const timezones = [
  "US/Alaska",
  "US/Aleutian",
  "US/Arizona",
  "US/Central",
  "US/East-Indiana",
  "US/Eastern",
  "US/Hawaii",
  "US/Indiana-Starke",
  "US/Michigan",
  "US/Mountain",
  "US/Pacific",
  "US/Pacific-New",
  "US/Samoa",
  "UTC",
];

export const drawerWidth = 310; // NAV SIDE BAR WIDTH ON ACTIVE STATE
export const LogoutText = "Logout";
export const AdminText = "Configurations";
export const RefreshText = "Refresh Data";
export const ClearLogs = "Clear Logs";
export const fileExplorer = "File Explorer";
