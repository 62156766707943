import * as React from "react";
import dayjs from "dayjs";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { useDispatch, useSelector } from "react-redux";
import { setConfigVarValues } from "../../../../app/features/flow/flowSlice";

export default function CustomDatePicker({ configVar }) {
  const [value, setValue] = React.useState(configVar?.defaultValue);
  const { configVarValues } = useSelector((state) => state.flows);
  const dispatch = useDispatch();

  let views = ["day"];
  let format = "DD-MM-YYYY";

  if (configVar.datePickerType) {
    if (configVar.datePickerType === "month") {
      views = ["month", "year"];
    }

    if (configVar.datePickerType === "year") {
      views = ["year"];
    }
  }

  if (configVar.format) {
    format = configVar.format;
  }

  // set standard display format
  let inputDisplayFormat = "DD/MM/YYYY";

  if (configVar.datePickerType === "month") {
    inputDisplayFormat = "MMM YYYY";
  }

  if (configVar.datePickerType === "year") {
    inputDisplayFormat = "YYYY";
  }

  if (configVar.format) {
    inputDisplayFormat = configVar.format;
  }

  const handleChange = (newValue) => {
    let formattedValue = dayjs(newValue).format(format);

    // offset to start if dateOffset is start
    if (configVar.dateOffset === "start") {
      formattedValue = dayjs(newValue)
        .startOf(configVar.datePickerType)
        .format(format);
    }

    // offset to end if dateOffset is end
    if (configVar.dateOffset === "end") {
      formattedValue = dayjs(newValue)
        .endOf(configVar.datePickerType)
        .format(format);
    }

    const obj = {};
    obj[configVar.name] = formattedValue;

    dispatch(setConfigVarValues({ ...configVarValues, ...obj }));

    setValue(newValue);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Stack spacing={3}>
        <DesktopDatePicker
          views={views}
          openTo="month"
          label="Date desktop"
          inputFormat={inputDisplayFormat}
          value={value}
          onChange={handleChange}
          renderInput={(params) => <TextField {...params} />}
        />
      </Stack>
    </LocalizationProvider>
  );
}
